<template>
  <a-row class="p-sm-4" :gutter="[16, 16]">
    <p>
      <strong>Note:</strong> use
      <strong style="color: darkred;font-size: 20px;background-color: lavender">
        .*</strong
      >
      regex to select all Organizations or Users
    </p>
  </a-row>

  <a-row class="p-4" :gutter="[16, 16]">
    <a-col span="10">
      <a-select
        ref="select"
        v-model:value="orgname"
        :options="orgOptions"
        style="min-width: 200px"
        placeholder="Select Organizations"
        mode="tags"
        @change="handleOrgChange"
      ></a-select>
      <a-select
        ref="select"
        v-model:value="username"
        :options="userOptions"
        style="min-width: 200px"
        placeholder="Select Users"
        mode="tags"
      ></a-select>
    </a-col>
    <a-col span="10" offset="0">
      <a-range-picker
        v-model:value="dateTime"
        show-time
        :ranges="dateRanges"
        format="YYYY/MM/DD hh:mm a"
      />
    </a-col>
    <a-col span="2">
      <a-button type="primary" @click="handleSubmit">Submit</a-button>
    </a-col>
    <a-col span="24">
      <a-table :dataSource="records" :columns="columns">
        <template #bodyCell="{ text, column, record }">
          <span v-if="column.dataIndex === 'response_status_info'">
            <a-tag
              v-if="record.request_full_path === 'Session Started'"
              color="green"
              >Session Started</a-tag
            >
            <a-tag
              v-else-if="record.request_full_path === 'Session Ended'"
              color="red"
              >Session Ended</a-tag
            >
            <a-tag v-else color="blue">{{ text }}</a-tag>
          </span>
          <span v-if="column.dataIndex === 'request_body_dict' && text">
            <a-popover title="Request Body" trigger="click">
              <template #content>
                <pre>{{ text }}</pre>
              </template>
              <a-button type="link">Show</a-button>
            </a-popover>
          </span>
          <span v-if="column.dataIndex === 'next_event_time'">
            {{ secondsToDays(text) }}
          </span>
          <span v-if="column.dataIndex === 'log_datetime'">
            {{ dateHelper.formatDate(text) }}
          </span>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import httpClient from '../../../../service/httpClient';
import dateHelper from '../../../shared/Helpers/dateHelper';

export default defineComponent({
  setup() {
    const orgname = ref([]);
    const username = ref([]);
    const records = ref([]);
    const dateRanges = {
      Today: [
        dayjs()
          .subtract(dayjs().hour(), 'hour')
          .subtract(dayjs().minute(), 'minute')
          .subtract(dayjs().second(), 'second'),
        dayjs(),
      ],
      '1 Day': [dayjs().subtract(1, 'day'), dayjs()],
      '1 Week': [dayjs().subtract(1, 'week'), dayjs()],
      '1 Month': [dayjs().subtract(1, 'month'), dayjs()],
    };
    const dateTime = ref(dateRanges['1 Day']);

    let orgUsers = {};
    let orgOptions = ref();
    let userOptions = ref();

    async function setUserOptions() {
      const url = `account/users_list?is_active=true`;
      const response = await httpClient.get(url);

      for (let elem = 0; elem < response.length; elem++) {
        let org = response[elem]['Organization'];
        if (org === null) {
          org = '<no-organization>';
        }
        let user = response[elem]['email'].toLowerCase();

        if (!(org in orgUsers)) {
          orgUsers[org] = [];
        }
        orgUsers[org].push(user);
      }
      orgOptions.value = Object.keys(orgUsers)
        .sort()
        .map(function(element) {
          return { value: element, label: element };
        });
      userOptions.value = Object.keys(orgUsers)
        .reduce(function(res, v) {
          return res.concat(orgUsers[v]);
        }, [])
        .sort()
        .map(function(element) {
          return { value: element, label: element };
        });
    }
    onMounted(() => {
      setUserOptions();
    });

    const columns = [
      {
        title: 'Date',
        dataIndex: 'log_datetime',
        key: 'log_datetime',
      },
      {
        title: 'Next Event Time',
        dataIndex: 'next_event_time',
        key: 'next_event_time',
      },
      {
        title: 'Username',
        dataIndex: 'request_username',
        key: 'request_username',
      },
      {
        title: 'Event',
        dataIndex: 'request_full_path',
        key: 'request_full_path',
      },
      {
        title: 'Method',
        dataIndex: 'request_method',
        key: 'request_method',
      },
      {
        title: 'Status',
        dataIndex: 'response_status_info',
        key: 'response_status_info',
      },
      {
        title: 'Request Body',
        dataIndex: 'request_body_dict',
        key: 'request_body_dict',
      },
    ];

    async function handleSubmit() {
      const start = dateTime.value[0].toISOString();
      const end = dateTime.value[1].toISOString();
      const orgnameRegex = orgname.value
        .map(function(elem) {
          return '(' + elem + ')';
        })
        .join('|');
      const usernameRegex = username.value
        .map(function(elem) {
          return '(' + elem + ')';
        })
        .join('|');

      const url = `account/generate_user_report?orgname=${orgnameRegex}&username=${usernameRegex}&start_date=${start}&end_date=${end}`;
      const response = await httpClient.get(url);
      records.value = response;
    }
    function secondsToDays(seconds) {
      seconds = Number(seconds);
      const d = Math.floor(seconds / (3600 * 24));
      const h = Math.floor((seconds % (3600 * 24)) / 3600);
      const m = Math.floor((seconds % 3600) / 60);

      const dDisplay = d > 0 ? d + 'd ' : '';
      const hDisplay = h > 0 ? h + 'h ' : '';
      const mDisplay = m > 0 ? m + 'm ' : '';
      let display = dDisplay + hDisplay + mDisplay;

      const s = display ? Math.floor(seconds % 60) : (seconds % 60).toFixed(2);
      const sDisplay = s > 0 ? s + 's ' : '';
      display += sDisplay;
      if (!display) {
        display = (0).toFixed(2) + 's';
      }
      return display;
    }
    function jsonPretty(value) {
      const newJson = JSON.stringify(value, null, 2);
      return newJson;
    }
    function handleOrgChange(value) {
      username.value = [];

      if (value.length === 0) {
        userOptions.value = Object.keys(orgUsers)
          .reduce(function(res, v) {
            return res.concat(orgUsers[v]);
          }, [])
          .sort()
          .map(function(element) {
            return { value: element, label: element };
          });
        return;
      }

      let user_list = [];
      value.forEach(
        (element) => (user_list = user_list.concat(orgUsers[element]))
      );
      userOptions.value = user_list.sort().map(function(element) {
        return { value: element, label: element };
      });
    }

    return {
      dateHelper,
      orgOptions,
      userOptions,
      orgname,
      username,
      records,
      columns,
      dateTime,
      dateRanges,
      handleOrgChange,
      handleSubmit,
      secondsToDays,
      jsonPretty,
    };
  },
});
</script>

<style scoped></style>
